/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included
 * directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "themes/theme-default-variables";

// 3rd party libraries
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
// Theme customization

@import "themes/theme";

// Components globals
@import "themes/components/general";
@import "themes/components/page";
@import "themes/components/icons";
@import "themes/components/buttons";
@import "themes/components/form";
@import "themes/components/lists";
@import "themes/components/alerts";
@import "themes/components/box";
@import "themes/components/modal";
@import "themes/components/document";

@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select.ng-select-multiple {
  .ng-select-container {
    .ng-value-container {
      min-height: 40px;
      .ng-placeholder {
        top: 9px;
        font-size: 0.9rem;
      }
      .ng-value {
        background-color: #efefef;
        padding: 3px;
        .ng-value-icon.left {
          border-right: 1px solid #dadada;
        }
        .ng-value-icon:hover {
          background-color: #dadada;
        }
      }
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  background-color: #efefef;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-bottom: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &.round {
    border-radius: 34px;
    &::before {
      border-radius: 50%;
    }
  }
  &::before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input:checked + .slider {
  background-color: #3ecb90;
  &::before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px #3ecb90;
}
