@import "src/themes/theme-simple-variables";
@import "~bootstrap/scss/mixins/_breakpoints";

/*
 * extend style for inputs and forms.
 */
input.form-control,
select.form-control,
textarea.form-control {
  font-size: 0.8rem;
  height: 46px;
  background-color: #fafafa;
  border-color: $gray-200;
  color: #a5a7a4;

  &::placeholder {
    color: $gray-300;
  }
}

.form-control {
  &.error {
    color: $red;
    border-color: $red;

    &:focus {
      color: $red;
      box-shadow: 0 0 0 0.2rem $red-100;
    }
  }
}

.input-group-text {
  background-color: #fafafa;
  border-color: $gray-200;
}

.error {
  font-size: 0.75rem;
  color: $red;

  .text-muted {
    font-size: 0.75rem;
    color: $red;
  }
}

.input-info {
  color: $gray-300;
  font-size: 0.75rem;
}

.ng-select.custom {
  font-size: 0.75rem;
  background-color: $white;
  min-height: 0;
}

.ng-select.custom .ng-select-container {
  font-size: 0.75rem;
  background-color: $white;
  min-height: 0;
}

textarea.bg-white {
  background-color: $white;
}

.form-modal {
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;

  @include media-breakpoint-up(lg) {
    padding-left: 50px;
    padding-right: 50px;
  }

  label,
  .error {
    font-size: 0.75rem;
  }

  .error {
    color: $red;
  }

  input.form-control,
  select.form-control,
  textarea.form-control {
    font-size: 0.8rem;
    background-color: $white;
    border: 1px solid $gray-280;
  }

  input.form-control,
  select.form-control {
    min-height: 36px;
  }

  .ng-select.custom {
    font-size: 0.75rem;
    background-color: $white;
    border: 1px solid $gray-280;
    min-height: 0;
  }

  .ng-select.custom .ng-select-container {
    font-size: 0.75rem;
    background-color: $white;
    border: 1px solid $gray-280;
    min-height: 0;
  }
}

.form-modal-signature {
  textarea.form-control {
    border: 1px solid $gray-300;
    color: $gray-600;
    min-height: 200px;
    font-size: 0.8rem;
    padding: 15px 20px;

    &::placeholder {
      color: $gray-600;
    }
  }

  .buttons {
    margin: 30px -30px -20px;
    padding: 20px 30px;
    background-color: $gray-050;
    text-align: right;

    .btn {
      text-transform: uppercase;
      border-radius: 0;
      margin-left: 10px;
    }
  }
}
