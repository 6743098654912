@import "src/themes/theme-simple-variables";
@import "~bootstrap/scss/mixins/_breakpoints";

/*
 * extend style for buttons.
 */

.btn-list {
  background-color: $gray-250;
  color: $gray-600;

  &:hover {
    background-color: $gray-100;
  }
}

.btn-primary {
  background-color: $v2-primeira;
  border-color: $v2-primeira;
  font-size: 0.8rem;
  &:hover {
    background-color: rgba($v2-primeira, 0.95);
  }
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: $v2-primeira;
  border-color: $v2-primeira;
}

.btn-secondary {
  background-color: $v2-segunda;
  border-color: $v2-segunda;
  font-size: 0.8rem;
  &:hover {
    background-color: rgba($v2-segunda, 0.8);
    border-color: rgba($v2-segunda, 0.8);
  }
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: $v2-segunda;
  border-color: $v2-segunda;
}

.btn-default {
  background-color: transparent;

  &.dropdown-toggle::after {
    border-top: 0.1em solid;
    border-left: 0.1em solid;
    border-bottom: 0;
    border-right: 0;
    transform: rotate(-135deg);
    display: inline-block;
    width: 0.3em;
    height: 0.3em;
    margin-left: 0.255em;
    vertical-align: 0.2em;
    content: "";
  }

  &.btn:hover {
    background-color: $gray-100;
  }
}

.btn {
  padding: 0.7rem 1rem;
}

.btn-rounded {
  border-radius: 25px;
  padding: 10px 35px;
}

.btn-white {
  background-color: $gray-250;
  color: $v2-primeira;

  &:hover {
    background-color: $gray-100;
  }
}

.btn-disabled {
  background-color: $gray-400;
  color: $white;
  opacity: 0.65;

  &:hover {
    background-color: $v2-segunda;
  }
}
