@import "src/themes/theme-simple-variables";
@import "~bootstrap/scss/mixins/_breakpoints";

/*
 * extend style for alerts.
 */
.alert-bar {
  font-size: 0.85rem;
  background: white;
  position: relative;
  border: none;
  padding-left: 30px;
  border-radius: 0.45rem;
  overflow: hidden;
  box-shadow: 0 2px 21px -2px rgba(0, 0, 0, 0.1);

  &::before {
    content: "";
    width: 7px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.alert-success {
    &::before {
      background-color: $green-100;
    }
  }

  &.alert-invalid {
    &::before {
      background-color: $red;
    }
  }

  &.alert-primary {
    &::before {
      background-color: $blue;
    }
  }
}

.alert {
  margin: 0;
  padding: 0.6rem 0.8rem;
  border-radius: 3px;
  &.alert-primary {
    background-color: $blue;

    .btn:hover {
      color: $blue;
    }
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
  }

  .message,
  .btn {
    color: $white;
    font-size: 0.85rem;

    i {
      font-size: 0.95rem;
      margin-right: 5px;
    }
  }

  .action {
    margin-top: 10px;
    display: flex;

    @include media-breakpoint-up(sm) {
      margin: 0;
    }

    a,
    .btn {
      margin: 0 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
