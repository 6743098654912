@import "src/themes/theme-simple-variables";
@import "~bootstrap/scss/mixins/_breakpoints";

/*
 * create style for lists.
 */

.search .actions {
  margin-bottom: 5px;
}

.search .actions span {
  font-size: 0.8rem;
}

.search .actions .arrow {
  height: 5px;
  margin-left: 5px;
  font-size: 16px;
  cursor: pointer;
}

.search .actions .filter {
  height: 13px;
  margin-right: 10px;
}

.line input[type="checkbox"] {
  transform: scale(1.2);
}

.line td {
  font-size: 0.8rem;
}

.line button {
  font-size: 0.7rem;
  min-width: 70px;
}

.line button:first-child {
  margin-right: 7px;
}

@include media-breakpoint-between(xs, sm) {
  .line button:first-child {
    margin-right: 0;
    margin-bottom: 3px;
  }
}

.table-container {
  padding: 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
  margin-top: 30px;
  border-radius: 5px;
  .icon {
    width: 12px;
    height: 12px;
  }

  .sign {
    color: $gray-300;
  }

  .resend {
    color: $red;
  }

  .move {
    color: $blue-light;
  }

  .archive {
    color: $green-700;
  }
}

.table-responsive {
  overflow-x: auto;
  margin-bottom: 0;
}
.table {
  margin-bottom: 0;
}

.table th,
.table td {
  border-top: 1px solid $gray-100;
  color: $blue-700;
  font-size: 0.8rem;
  vertical-align: middle;
  padding: 0.85rem;

  &:first-child {
    padding-left: 1.5rem;
  }
  &:last-child {
    padding-right: 1.5rem;
  }
}
.table thead th {
  border-bottom: 2px solid #e4e4e4;
}
thead {
  th {
    border: none;
    padding-bottom: 1.2rem;
    span {
      font-size: 0.75rem;
    }
    .sortable {
      cursor: pointer;
    }
    i {
      padding: 0 5px;
    }
  }
}

tbody {
  &.box {
    border-radius: 3px;
    background-color: transparent;
  }
  tr {
    &:first-child {
      td {
        border-top: none;
      }
    }

    td {
      background-color: $white;
    }
  }

  .child {
    td {
      border-bottom: 1px solid $gray-250;
      padding-bottom: 10px;
      position: relative;
      overflow: hidden;

      .details {
        &::before {
          content: " ";
          position: absolute;
          height: 1px;
          width: 100%;
          top: -1px;
          left: 0;
          box-shadow: 3px 10px 1px $gray-180;
        }
      }

      ol {
        padding-left: 15px;
        margin-bottom: 0;
      }

      li {
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        position: relative;

        &::after {
          content: " ";
          position: absolute;
          bottom: 0;
          left: -15px;
          width: 100%;
          height: 1;
          border-bottom: 1px solid $gray-250;
        }

        &:last-child {
          margin: 0;
          padding: 0;

          &::after {
            display: none;
          }
        }
      }

      span {
        font-weight: normal;
        color: $blue-900;
      }
    }
  }
}

td {
  .btn-light,
  .dropdown-toggle-split {
    border-color: $gray-300;
    &:hover {
      border-color: $gray-300;
    }
  }
  .btn-light {
    color: $blue-700;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    font-size: 0.8rem;
  }
  .dropdown-toggle-split {
    min-width: 20px;
    max-width: 20px;
    background-color: $white;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
  }

  .dropdown-item {
    font-size: 0.8rem;
  }

  .sign {
    color: $green-700;
    &.fa::after {
      content: "\f00c";
    }
  }

  .circulating {
    color: $blue;
    &.fa::after {
      content: "\f2f2";
      color: $gray-300;
    }
  }

  .archive {
    color: $gray-300;
  }

  .expired {
    color: $red-100;
  }

  .cancelled-line-through {
    text-decoration: line-through;
  }

  .cancelled,
  .reject {
    color: $red;

    &.fa::after {
      content: "\f00d";
    }
  }

  .draft {
    color: $gray-300;
  }
}

.page-item {
  .page-link {
    border-color: $gray-280;
  }
  &.disabled {
    .page-link {
      border-color: $gray-280;
      color: $gray-280;
    }
  }

  &.active {
    .page-link {
      background-color: $green;
      border-color: $green;
    }
  }
}

select.custom {
  cursor: pointer;
  height: 35px;
  width: 120px;
  text-align: center;
  border-radius: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
  appearance: button;
  color: $v2-primeira;
  border: 1px solid #e2e2e2;
  background-color: #fff;
  padding: 0 15px 0 10px;
  font-weight: 600;
  margin-left: 5px;
}
select.custom option {
  padding: 10px;
}

.pagination-block {
  margin-top: 30px;
  .page-item .page-link {
    border-color: #e9e9e9;
    padding: 0.7rem 1rem;
    font-weight: 600;
  }
  .page-item.active .page-link {
    background-color: #fff;
    color: $v2-segunda;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background-color: $v2-segunda;
      width: 30%;
      height: 0.1rem;
      bottom: 0.6rem;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
