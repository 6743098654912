/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
.hero {
  background: #023d52;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 300px;

  @include media-breakpoint-up(lg) {
    min-height: 400px;
  }

  h2 {
    max-width: 500px;
    position: relative;
  }

  .btn {
    margin-top: 20px;
    text-transform: uppercase;
  }
}
:focus {
  outline: none;
}
.modal-backdrop {
  background-color: #666;
}

.modal-content {
  border: none;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
