@import "src/themes/theme-simple-variables";

/*
 * General page styles
 */

.page {
  background-color: $gray-150;
  padding: 0;
  min-height: calc(100% - 142px);

  h2 {
    color: $blue-700;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 12px 0;
  }

  h3 {
    color: $blue-700;
    font-size: 1.2rem;
  }

  p {
    color: $gray-400;
  }

  &-breadcrumb {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: none;
      font-size: 0.65rem;
      font-weight: 600;
      &.active {
        display: inline-block;

        &::before {
          content: " > ";
          display: inline-block;
          color: $gray-400;
          margin-left: 5px;
          margin-right: 5px;
        }

        &:first-child {
          &::before {
            display: none;
          }
        }
      }

      a {
        font-size: 0.65rem;
        color: $gray-400;

        &:hover {
          color: $blue-700;
        }
      }
    }
  }
}
