@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?d8mw1c');
  src: url('fonts/icomoon.eot?d8mw1c#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?d8mw1c') format('truetype'),
    url('fonts/icomoon.woff?d8mw1c') format('woff'),
    url('fonts/icomoon.svg?d8mw1c#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cloudwhite15x:before {
  content: '\e900';
  color: #fff;
}
.icon-documentsign15x .path1:before {
  content: '\e901';
  color: rgb(0, 153, 255);
}
.icon-documentsign15x .path2:before {
  content: '\e902';
  margin-left: -0.8359375em;
  color: rgb(29, 153, 108);
}
.icon-documentsign15x .path3:before {
  content: '\e903';
  margin-left: -0.8359375em;
  color: rgb(255, 255, 255);
}
.icon-documentsign15x .path4:before {
  content: '\e904';
  margin-left: -0.8359375em;
  color: rgb(0, 153, 255);
}
.icon-dots:before {
  content: '\e905';
  color: #de4759;
}
.icon-dots15x:before {
  content: '\e906';
  color: #fff;
}
.icon-drag-handle-icon:before {
  content: '\e907';
  color: #09f;
}
.icon-edit15x:before {
  content: '\e908';
  color: #0c4d64;
}
.icon-fill-1:before {
  content: '\e909';
  color: #de4759;
}
.icon-filters15x:before {
  content: '\e90a';
  color: #54698d;
}
.icon-folder-icon-15x .path1:before {
  content: '\e90b';
  color: rgb(46, 146, 255);
}
.icon-folder-icon-15x .path2:before {
  content: '\e90c';
  margin-left: -1.115234375em;
  color: rgb(224, 238, 253);
}
.icon-folder-icon .path1:before {
  content: '\e90d';
  color: rgb(46, 146, 255);
}
.icon-folder-icon .path2:before {
  content: '\e90e';
  margin-left: -1.115234375em;
  color: rgb(224, 238, 253);
}
.icon-folder-icon-copy .path1:before {
  content: '\e90f';
  color: rgb(46, 146, 255);
}
.icon-folder-icon-copy .path2:before {
  content: '\e910';
  margin-left: -1.115234375em;
  color: rgb(224, 238, 253);
}
.icon-icon:before {
  content: '\e911';
}
.icon-move15x:before {
  content: '\e912';
  color: #168878;
  font-size: 17px;
}
.icon-notifications:before {
  content: '\e913';
  color: #0c4d64;
}
.icon-phone-donwload15x:before {
  content: '\e914';
  color: #09f;
}
.icon-plus15x:before {
  content: '\e915';
  color: #168878;
}
.icon-shape:before {
  content: '\e916';
  color: #0c4d64;
}
.icon-shield15x:before {
  content: '\e917';
  color: #fff;
}
.icon-signuser15x:before {
  content: '\e918';
  color: #fff;
}
.icon-support15x:before {
  content: '\e919';
  color: #1d996c;
}

.documento-termo {
  font-size: 0.8rem;
}
.documento-termo h1 {
  font-size: 1.3rem;
}
.documento-termo h2 {
  font-size: 1.2rem;
}
.documento-termo h3 {
  font-size: 1.1rem;
}
.documento-termo h4 {
  font-size: 1rem;
}
.documento-termo h5 {
  font-size: 0.5rem;
}
.documento-termo h6 {
  font-size: 0.8rem;
}
.documento-termo ul {
  padding-left: 15px;
  margin-bottom: 15px;
  list-style-type: none;
}
h1.documento-termo--title {
  font-size: 1.1rem;
}
h2.documento-termo--subtitle {
  font-size: 0.9rem;
}
