@import "src/themes/theme-simple-variables";

/*
 * extend style for box elements.
 */
.box {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.03);

  .box-header {
    padding: 15px 20px;
    border-bottom: 1px solid $gray-150;

    h3 {
      margin: 0;
      padding: 0;
      color: $v2-primeira;
      font-size: 1.1rem;
      font-weight: 600;
    }

    i {
      margin-right: 9px;
      &.fa-times-circle {
        color: $red;
      }
    }

    &.button {
      align-items: center;
      display: flex;

      .btn {
        margin: 0 15px;
      }
    }
  }

  .box-content {
    padding: 15px 20px;
  }

  .box-footer {
    padding: 15px 20px;
    border-top: 1px solid $gray-150;

    .filter-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      .filter {
        .form-control {
          color: $gray-300;
        }
      }

      .action {
        padding-left: 10px;
        text-align: right;
        width: 75%;

        a {
          color: $blue-700;
          font-size: 0.75rem;

          span {
            font-size: 1rem;
          }
        }
      }
    }
  }

  label,
  input,
  select {
    color: $gray-550;
    font-size: 0.8rem;
  }

  input.form-control {
    background-color: white;
  }

  select {
    border-color: $gray-200;
  }

  .options {
    border-top: 1px solid $gray-200;
    margin: 15px 0;
    padding: 15px 0 0;
    color: $green;

    .btn {
      padding: 0;
      min-width: auto;
      color: $green;
      font-size: 0.75rem;

      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }
}
