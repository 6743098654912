/*
 * extend style adding icons
 */
.icon-exclamation {
  background: url("/assets/images/icon-exclamation.svg") no-repeat center center;
  width: 125px;
  height: 125px;
  display: block;
}

.icon-support {
  background: url("/assets/images/support.svg") no-repeat center center;
  display: block;
  width: 45px;
  height: 45px;
}

.icon-phone-download {
  background: url("/assets/images/phone-donwload.png") no-repeat center center;
  display: block;
  width: 45px;
  height: 60px;
  background-size: contain;
}

.icon-cloud {
  background: url("/assets/images/cloud.png") no-repeat center center;
  display: block;
  width: 36px;
  height: 31px;
  background-size: contain;
}

.icon-cloud-btn {
  background: url("/assets/images/cloudwhite.png") no-repeat center center;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.icon-document-error {
  background: url("/assets/images/document-error.svg") no-repeat center center;
  display: block;
  width: 51px;
  height: 61px;
  background-size: contain;
}

.icon-document-ok {
  background: url("/assets/images/icon-5.svg") no-repeat center center;
  display: block;
  width: 51px;
  height: 61px;
  background-size: contain;
}

.icon-teacher {
  background: url("/assets/images/icon1@1.5x.svg") no-repeat center center;
  display: block;
  width: 58px;
  height: 64px;
  background-size: contain;
}

.icon-pointer {
  background: url("/assets/images/icon-2@1.5x.svg") no-repeat center center;
  display: block;
  width: 65px;
  height: 65px;
  background-size: contain;
}

.icon-direction {
  background: url("/assets/images/icon-3@1.5x.svg") no-repeat center center;
  display: block;
  width: 64px;
  height: 64px;
  background-size: contain;
}

.icon-metrics {
  background: url("/assets/images/icon-4@1.5x.svg") no-repeat center center;
  display: block;
  width: 64px;
  height: 56px;
  background-size: contain;
}
