@import "src/themes/theme-simple-variables";

.modal-dialog {
  padding: 0 8px;
}

/*
 * extend style for modals.
 */
.modal-header,
.modal-footer,
.modal-content {
  background-color: $white;
}

.modal-header {
  .close {
    span {
      color: $gray-400;
      font-size: 1.5rem;
    }

    &:focus {
      outline: none;
      color: $gray-400;
    }
  }
}

.simple {
  .modal-header {
    h4 {
      width: 100%;
      text-align: center;
      font-weight: 600;
    }
  }

  .modal-footer {
    border: none;
    justify-content: center;
  }
}

.signature {
  .modal-header {
    border-radius: 0;

    h4 {
      font-weight: 600;
      font-size: 1.4rem;
      margin: 8px 0 0 15px;
    }
  }

  .modal-body {
    padding: 20px 30px;
  }

  .modal-footer {
    border-radius: 0;
    padding: 15px 30px;

    .btn {
      border-radius: 0;
      text-transform: uppercase;
      margin-left: 10px;
    }
  }

  &.green {
    .modal-header {
      background-color: transparent;
      border-top: 5px solid $green-100;
      color: $gray-700;
      border-bottom: 0;
    }

    .modal-footer {
      background-color: $gray-050;
      border-top: 0;
    }
  }
}

.green,
.red {
  .modal-header {
    background-color: $green-100;
    color: $white;
  }

  .modal-footer,
  .modal-content {
    .btn {
      font-weight: bold;
    }

    .btn-default {
      color: $gray-600;
    }

    .btn-secondary {
      background-color: $green-100;

      &:hover {
        background-color: $green;
      }
    }
  }
}

.red {
  .modal-header {
    background-color: $red;
    color: $white;
  }
}
