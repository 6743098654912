@import "src/themes/theme-default-variables";

/*
 * General styles
 */
html,
body {
  height: 100%;
}

body {
  color: $gray-700;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}

.brand {
  background: url("/assets/images/logo.svg") no-repeat center center;
  position: relative;
  overflow: hidden;
  text-indent: -99rem;
  display: block;

  &.inverse {
    background: url("/assets/images/logo-inverse.svg") no-repeat center center;
  }

  &.large {
    width: 200px;
    height: 34px;
    transform: scale(0.9);
    margin-left: -14px;
    margin-top: -5px;
  }
}

.hidden {
  display: none;
}

p,
a:not(.btn),
span {
  font-size: 0.85rem;
}

h2 {
  font-size: 2.45rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.2rem;
}

h6 {
  color: $gray-400;
  font-size: 0.85rem;
  margin-bottom: 1rem;
}

a {
  color: $green-100;

  &.styled {
    text-decoration: underline;
  }

  &.secondary {
    color: $blue;
  }
}

a:hover,
a:active {
  color: $green;

  &.secondary {
    color: $blue-700;
  }
}

.pointer {
  cursor: pointer;
}

.btn {
  padding: 0.7rem 2rem;
  font-weight: 600;
  font-size: 0.8rem;
  border-radius: 3px;
  min-width: 120px;
}

.btn-secondary {
  background-color: #3ecb90;
  border-color: #3ecb90;
}

.feedback .alert {
  border-radius: 0;
  border: none;
  padding: 1rem;
}

.navbar {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  font-weight: 600;
}
